import { resourceTypes } from './resourceTypes'

// Regular expression for identifying YouTube URLs
const youtubeRegExp = /^(https?:\/\/)?(www\.)?youtube\.com\/(?:watch\?v=|embed\/)([a-zA-Z0-9_-]{11})/;

// Lists of accepted file extensions and formats
const acceptedImageFilesExtensions = ['jpeg', 'JPEG', 'jpg', 'JPG', 'png', 'PNG', 'svg', 'SVG', 'gif', 'GIF']
const acceptedVideoFilesExtensions = ['mp4', 'MP4', 'avi', 'AVI']
const acceptedDocumentFilesExtensions = ['pdf', 'PDF', 'ppt', 'PPT', 'pptx', 'PPTX', 'doc', 'DOC', 'docx', 'DOCX']
const acceptedYoutubeFilesExtensions = ['youtube', 'YOUTUBE']

// Lists of accepted Google Docs domains
const acceptedGoogleDocsFilesDomains = ['docs.google.com', 'drive.google.com']

// Lists of accepted file formats
const acceptedImageFilesFormats = ['image/jpeg', 'image/jpg', 'image/png', 'image/svg+xml', 'image/gif']
const acceptedVideoFilesFormats = ['video/mp4', 'video/avi', 'video/x-msvideo']
const acceptedDocumentFilesFormats = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.slideshow', 'application/vnd.openxmlformats-officedocument.presentationml.presentation']

// Combining accepted extensions and formats into lists
 const acceptedAllFilesExtensions = [
  ...acceptedImageFilesExtensions,
  ...acceptedVideoFilesExtensions,
  ...acceptedDocumentFilesExtensions,
  ...acceptedYoutubeFilesExtensions
]

const acceptedAllFilesFormats = [
  ...acceptedImageFilesFormats,
  ...acceptedDocumentFilesFormats,
  ...acceptedVideoFilesFormats,
]

// Exporting lists of accepted file extensions and formats
export const acceptedFileExtensions = {
  acceptedImageFilesExtensions,
  acceptedVideoFilesExtensions,
  acceptedDocumentFilesExtensions,
  acceptedYoutubeFilesExtensions,
  acceptedAllFilesExtensions
}

export const acceptedFileFormats = {
  acceptedImageFilesFormats,
  acceptedVideoFilesFormats,
  acceptedDocumentFilesFormats,
  acceptedAllFilesFormats
}

// Function to download a file
export const downloadFile = (fileUrl, fileExtension) => {
  fetch(fileUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(new Blob([blob]))
        const fileName = getFileNameWithoutExtension(fileUrl)
        const tempLink = Object.assign(document.createElement('a'), {
          href: url,
          download: `${fileName}.${fileExtension}`,
          style: 'display: none',
        })
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
        URL.revokeObjectURL(url)
      })
      .catch((error) => {
        console.error('Error downloading file:', error)
      })
}

// Function to extract the file name without extension from a URL
export const getFileNameWithoutExtension = (url) => {
  // Decode the URL to handle special characters and split it by "?"
  const decodedUrl = decodeURIComponent(url).split("?")[0].split("/")
  // Check if the URL has at least three segments (typically protocol, domain, and file path)
  if (decodedUrl && decodedUrl.length >= 3) {
    // Extract the last segment (file name with extension) and split it by underscores
    const fileNameWithExtension = decodedUrl[decodedUrl.length - 1].split("_").slice(1).join("_")
    // Split the file name with extension by the last period to remove the extension
    return fileNameWithExtension.split('.').slice(0, -1).join('.')
  }
  return ""
}


export const getVideoTypeFromUrl = (url) => {
  if (typeof url !== 'string') {
    return ''
  }
  const fileExtension = getFileExtension(url)
  if (acceptedVideoFilesExtensions.includes(fileExtension)) {
    return `video/${fileExtension}`
  }
  return ''
}

// Function to check if a URL is a YouTube video
export const isYoutubeUrl = (resourceLink) => {
  const match = resourceLink.match(youtubeRegExp)
  return match ? `https://www.youtube.com/embed/${match[3]}` : ''
}

// Function to check if a URL is a Google Docs link
export const isGoogleDocsUrl = (resourceLink) => {
  const parsedUrl = new URL(resourceLink)
  return acceptedGoogleDocsFilesDomains.some(domain => parsedUrl.hostname.includes(domain))
}

// Function to check if a URL is from Firebase Storage
export const isFirebaseStorageURL = (url) => {
  // Check if the URL does not start with "https://firebasestorage.googleapis.com"
  if (!url) {
    return false
  }
  if (typeof url !== 'string') {
    return false
  }
  return url.startsWith("https://firebasestorage.googleapis.com")
}

// Function to check if a URL points to a supported video
export async function isSupportedVideo(url, additionalExtensions = []) {
  const videoExtensions = ['.mp4', '.mov', '.wmv', '.flv', '.mkv', '.webm', ...additionalExtensions]

  // Check domain for known video platforms
  const knownVideoDomains = ['youtube.com', 'vimeo.com', 'dailymotion.com']
  const parsedURL = new URL(url)
  if (knownVideoDomains.includes(parsedURL.hostname)) {
    return true
  }

  // Check path and query parameters for known video URLs
  if (parsedURL.pathname.startsWith('/video/') && parsedURL.searchParams.has('id')) {
    return true
  }

  // Validate file extensions for direct video links
  const urlPath = parsedURL.pathname.toLowerCase()
  if (videoExtensions.some(ext => urlPath.endsWith(ext))) {
    return true
  }

  // If the URL directly points to a video file, check the Content-Type header
  try {
    const response = await fetch(url, { method: 'HEAD' })
    const contentType = response.headers.get('Content-Type') || ''
    if (contentType.startsWith('video/') && !urlPath.endsWith('.avi')) {
      return true
    }
  } catch (error) {
    // Error occurred while fetching, could be due to CORS or network issues, handle it as per your use case
    console.error(error)
  }

  return false
}

// Function to handle resource previews
export async function handlePreview(resourceLink) {
  if (resourceLink) {
    // Ensure URL starts with 'http://' or 'https://'
    if (!resourceLink.startsWith('http://') && !resourceLink.startsWith('https://')) {
      resourceLink = `https://${resourceLink}`
    }
    const payload = {
      resourceUrl: resourceLink,
      previewType: 'other',
    }
    const fileExtension = resourceLink.split('.').pop().split('?')[0]

    if (isGoogleDocsUrl(resourceLink)) {
      payload.previewType = 'googledoc'
    } else if (isYoutubeUrl(resourceLink)) {
      payload.previewType = 'youtube'
      // get the embed link
      payload.resourceUrl = isYoutubeUrl(resourceLink)
    } else if (await isSupportedVideo(resourceLink)) {   // "await isSupportedVideo(url, ['.avi']" With additional extensions (including ".avi")
      payload.previewType = 'mp4'
    } else if (acceptedFileExtensions.acceptedAllFilesExtensions.includes(fileExtension)) {
      payload.previewType = fileExtension
    } else {
      payload.previewType = "other"
    }

    return payload
  }
}

// Function to get the file extension from a filename
const getFileExtension = (filename) => {
  return filename.split('.').pop().toLowerCase().trim()
}

// Function to generate an attachment from a file
export const generateAttachment = (file) => {
  const attachment = {
    assetUrl: file,
    name: file.name
  }

  const fileExtension = getFileExtension(file.name)

  if (acceptedImageFilesExtensions.includes(fileExtension)) {
    return {
      ...attachment,
      type: resourceTypes.IMAGE
    }
  } else if (acceptedVideoFilesExtensions.includes(fileExtension)) {
    return {
      ...attachment,
      type: resourceTypes.VIDEO
    }
  } else if (acceptedDocumentFilesExtensions.includes(fileExtension)) {
    return {
      ...attachment,
      type: resourceTypes.FILE
    }
  } else {
    console.error("File format not supported.")
    return null
  }
}

// Function to create an attachment from a link
export const createLinkAttachment = (link) => {
  const attachment = {
    assetUrl: link
  }
  return {
    ...attachment,
    type: resourceTypes.LINK
  }
}



