const UTM_KEY = 'utm_params'
export const tracking = {
  getUtmParams: () => {
    return JSON.parse(localStorage.getItem(UTM_KEY))
  },
  setUtmParams: (value) => {
    localStorage.setItem(UTM_KEY, JSON.stringify(value))
  },
  clearUtmPrams: () => {
    localStorage.removeItem(UTM_KEY)
  },
  trackEvent: (eventName, data) => {
    if (window.dataLayer) {
        window.dataLayer.push({
          event: eventName,
          ...data
      })
    }
  }
}

export default tracking
