import axios from 'axios'
import {config} from "@/config"
import { processStatuses } from '@/utils'

export default {
  state: () => ({
    timeline_data: [],
    timeline_loading: true,
    timeline_error: {},
    timeline_preview: false,
    timeline_Status: processStatuses.IDLE,
    canTimelineUpdate: false,
    timelineOrignalData: []
  }),
  getters: {
    timelineData: state => state.timeline_data,
    timelineLoading: state => state.timeline_loading,
    timelineError: state => state.timeline_error,
    timelinePreview: state => state.timeline_preview,
    timelineStatus: state => state.timeline_Status,
    canTimelineUpdate: state => state.canTimelineUpdate,
    timelineOrignalData: state => state.timelineOrignalData
  },
  mutations: {
    SET_TIMELINE_DATA (state, data) {
      state.timeline_data = data
      state.timelineOrignalData = JSON.parse(JSON.stringify(data))
    },
    SET_TIMELINE_LOADING (state, status) {
      state.timeline_loading = status
    },
    SET_TIMELINE_ERROR (state, error) {
      state.timeline_error[error.id] = {...state.timeline_error[error.id], [error.key] : error.errMsg}
      state.timeline_Status = processStatuses.ERROR
    },
    TIMELINE_ERROR_RESET (state) {
      state.timeline_error = {}
    },
    TIMELINE_PREVIEW (state) {
      state.timeline_preview = true
    },
    TIMELINE_SAVING (state) {
      state.timeline_Status = processStatuses.SAVING
    },
    SET_TIMELINE_LOADED (state) {
      state.timeline_Status = processStatuses.LOADED
    },
    SET_TIMELINE_SUBMISSION_END_ERROR (state, error) {
      state.timeline_error[error.id] = {...state.timeline_error[error.id], [error.key] : error.errMsg}
    },
    SET_CAN_TIMELINE_UPDATE (state, status) {
      state.canTimelineUpdate = status
    }
  },
  actions: {
    getTimelineData({ commit }, eventId) {
      axios.get(`${config.API_URL}/timeline`,{ params: { eventId: eventId } }).then(res => {
        commit('SET_TIMELINE_DATA', JSON.parse(JSON.stringify(res.data.timeline)))
        commit('SET_TIMELINE_LOADED')
      })
    },
    //save Data and Disaplay in period
    setTimelineData({commit}, payload) {
      commit('TIMELINE_SAVING')
      return new Promise((resolve, reject) => {
        axios.patch(`${config.API_URL}/timeline/`+ payload.id, payload).then(res => {
          resolve(res)
          commit('SET_TIMELINE_LOADED')
        }).catch(error => {
          error.data.id= payload.id
          error.data.key = "timelineServerError"
          error.data.errMsg = error.data.message
          commit('SET_TIMELINE_ERROR', error.data)
          reject(error)
        })
      })
    },
    timelineErrorReset({ commit }) {
      commit('TIMELINE_ERROR_RESET', '')
    },
    setTimelinePreview({ commit }) {
      commit('TIMELINE_PREVIEW', '')
    },
    setSubmissionEndError({ commit }, payload) {
      commit('SET_TIMELINE_SUBMISSION_END_ERROR', { id: payload.id, key: 'endAt', errMsg: payload.message })
    },
    setCanTimelineUpdate({ commit }, status) {
      commit('SET_CAN_TIMELINE_UPDATE', status)
    }
  }
}
