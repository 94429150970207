<script setup>
import Overflow from '@/components/Base/Menu/Overflow'
import store from '@/store'
import { computed } from 'vue'
import {
  acceptedFileExtensions,
  getFileNameWithoutExtension,
  handlePreview,
} from '@/utils/file-handling'
import { bitwiseOperators } from "@/utils"
import Tooltip from '@/components/Lib/Tooltip'

const props = defineProps({
  resource: {
    type: Object,
    required: true
  },
  isLoading: {
    type: Boolean,
    default: false
  },
  isLast: {
    type: Boolean,
    required: false
  }
})

const event = computed(() => {
  return store.getters.eventBuilderEvent
})

const resourceCardImage = computed(() => {
  if(props.resource.resourceBanner && getFileNameWithoutExtension(props.resource.resourceBanner) !== '') {
    return props.resource.resourceBanner
  } else if(props.resource && props.resource.resourceLink !== '') {
    // For non-video URLs or URLs not directly pointing to video files
    let previewType = props.resource.resourceLink.split('.').pop().split('?')[0]

    if (acceptedFileExtensions.acceptedImageFilesExtensions.includes(previewType)) {
      return props.resource.resourceLink
    }
  }
  return props.resource.resourceBanner
})

const editResource = (resourceId) => {
  if (resourceId > 0) {
    store.dispatch('fetchResource', { resourceId: resourceId }).then(() => {
      store.dispatch('resetResource')
      store.dispatch('ToggleModalResource')
    })
  }
}

const fetchResources = () => {
  if (event.value.id > 0) {
    store.dispatch('fetchResources', { eventId: event.value.id }).then((res) => {
      if (res.length === 0) {
        store.dispatch('updateEventConfigs', { eventId: event.value.id, configKey: "event_config_completion", configValue: "4", isConfigValueBitMask: true, bitwiseOperation: bitwiseOperators.REMOVE }).then(() => {
          store.dispatch('getEventConfigs', { eventId: event.value.id })
        }).catch((err) => {
          console.error(err)
        })
      }
    })
  }
}

const deleteResource = async (resourceId) => {
  if (resourceId > 0) {
    const payload = {
      resourceId: resourceId,
      confirmButton: 'Delete',
      cancelButton: 'Cancel',
      confirmAction: async () => {
        try {
          await store.dispatch('deleteResource', { resourceId: resourceId })
          fetchResources()
          await store.dispatch('ToggleModalResourceDelete', payload)
          await store.dispatch('getEventConfigs', { eventId: store.getters.ebEventId}).then(() => {
            store.dispatch('GetEventBuilderItems')
          })
        } catch (err) {
          fetchResources()
          await store.dispatch('triggerToast',{ message: 'Failed to delete resource', type: 'error'})
          console.error(err)
        }
      },
      cancelAction: () => {
        store.dispatch('ToggleModalResourceDelete', payload)
      },
      type: 'confirmEvent'
    }
    await store.dispatch('ToggleModalResourceDelete', payload)
  }
}

const openPreview = async (resource) => {
  if (resource) {
    const payload = await handlePreview(resource.resourceLink)
    payload.metadata = resource.metadata ? JSON.parse(resource.metadata) : null
    if (payload) {
      // if previewType is not videoUrl, youtube Url or google doc
      if (payload.previewType === 'other') {
        window.open(payload.resourceUrl, '_blank')
      } else {
        store.dispatch('ToggleModalResourcePreview', payload)
      }
    }
  }
}
</script>

<template>
  <li v-if="!isLoading" class="card">
    <img :src="resourceCardImage"
         :alt="resource.headerInfoalt"
         class="card-img"
         :class="{'card-img-pointer': (resource.resourceLink)}"
         @click="openPreview(resource)"/>
    <div class="card-container">
      <span class="w-full relative">
        <tooltip direction="left"
                  :input="resource.fullTitle"
                  :max-length="30">
          <template #element>
            <h5 class="card-title">
              {{ resource.fullTitle }}
            </h5>
          </template>
          <template #content>
            {{resource.fullTitle}}
          </template>
        </tooltip>
        <span class="card-header">
          <overflow class="card-header-btn" position="left">
            <template #menu-items>
              <ul class="card-menu-list">
                <li class="card-menu-list-item"
                    @click.prevent="editResource(resource.id)">
                  <i class="fa-thin fa-pencil mr-2" />Edit Resource
                </li>
                <li class="card-menu-list-item"
                    @click.prevent="() => deleteResource(resource.id)">
                  <i class="fa-thin fa-trash-can mr-2" />Delete Resource
                </li>
              </ul>
            </template>
          </overflow>
        </span>
        <tooltip direction="left"
                 :input="resource.description">
          <template #element>
            <p class="card-description">
              {{resource.description}}
            </p>
          </template>
          <template #content>
            {{resource.description}}
          </template>
        </tooltip>
      </span>
    </div>
  </li>
  <li v-else class="card">
    <div class="card-img"></div>
    <div class="card-container">
      <span class="w-full relative">
          <h5 class="card-title"></h5>
          <span class="card-header">
              <span class="card-header-status">
                <span class="card-header_text">
                </span>
              </span>
          </span>
        <p class="card-description"></p>
      </span>
    </div>
  </li>
  <li v-if="isLast && isLoading" class="card card-me">
    <span class="card-container">
        <i class="card-icon" />
      <p class="card-description"></p>
    </span>
  </li>
</template>

<style lang="scss" scoped>
.card {
  @apply border rounded-xl w-[21.875rem] min-h-[17.063rem] bg-background-400;
  &-header {
    @apply text-xs absolute top-0 left-0 w-full flex flex-row justify-between;
    &-status {
      @apply flex flex-row;
    }
    &-btn {
      @apply ml-72;
    }
  }
  &-img {
    @apply h-[12rem] w-full object-cover rounded-t-xl hover:cursor-not-allowed;
    &-pointer {
      @apply hover:cursor-pointer;
    }
  }
  &-container {
    @apply flex flex-row w-full justify-between p-4 rounded-b-xl;

  }
  &-list {
    @apply bg-background-400;
  }
  &-title {
    @apply font-medium text-h5 line-clamp-1 pr-[2.5rem] break-words;
  }
  &-description {
    @apply text-p-xs text-blacks-300 pt-2 line-clamp-1 cursor-pointer;
    &-tooltip {
      &:hover {
        &:after {
          @apply bg-greys-100 text-blacks-500 left-0 right-0 absolute rounded-xl text-xs p-3 w-full z-10 bottom-[calc(100%-1rem)];
        }
        &:before {
          @apply bottom-[calc(100%-1.8rem)] absolute w-[1rem] mx-auto left-0 right-0 border border-solid border-t-[0.5rem] border-r-[0.5rem] border-b-[0.40rem] border-l-[0.40rem] border-t-greys-100 border-r-transparent border-b-transparent border-l-transparent;
        }
      }
    }
  }
  &-menu { // Project Card Header Menu
    &-list {
      @apply text-p-xs flex flex-col items-start;
      &-item {
        @apply px-5 py-3 cursor-pointer hover:text-primary w-full;
      }
    }
  }
  &-me {
    @apply flex justify-center items-center text-center border-2 rounded-xl border-dashed cursor-pointer p-5 w-[21.875rem] h-[17.063rem];
  }
  &-icon {
    @apply rounded-full w-[4.375rem] h-[4.375rem] leading-[4.375rem] mb-3 animate-[pulse-bg_2s_infinite] bg-background-500 bg-no-repeat bg-gradient-to-r from-background-500 via-blacks-100 to-background-500;
  }
}

.skeleton {
  .card {
    &-container {
      @apply flex-col justify-center items-center bg-transparent;
    }
    &-title {
      @apply animate-[pulse-bg_2s_infinite] w-full h-[1.5rem] bg-background-500 bg-no-repeat bg-gradient-to-r from-background-500 via-blacks-100 to-background-500;
    }
    &-description {
      @apply animate-[pulse-bg_2s_infinite] w-full h-[1rem] bg-background-500 bg-no-repeat bg-gradient-to-r from-background-500 via-blacks-100 to-background-500 mt-3;
    }
    &-img {
      @apply animate-[pulse-bg_2s_infinite] bg-background-500 bg-no-repeat bg-gradient-to-r from-background-500 via-blacks-100 to-background-500;
    }
  }
}
@keyframes pulse-bg {
  0% {
    @apply [background-position-x:-29rem];
  }

  100% {
    @apply [background-position-x:29rem];
  }
}
</style>
