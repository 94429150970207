<script setup>
import ModalBase from "@/components/Modals/ModalBase"
import BaseButton from '@/components/Base/Button/BaseButton';
import { computed } from 'vue'
import store from '@/store'

const titleImage = 'https://firebasestorage.googleapis.com/v0/b/test-d9796.appspot.com/o/selfserve%2Fgo-live.png?alt=media&token=634188ab-6b52-49bd-b897-976aae1a46e0'

const event = computed(() => {
  return store.getters.eventBuilderEvent
})
const cancel = () => {
  store.dispatch('ToggleModalGoLive')
  store.dispatch('ToggleModalEventDraft')
}
const timelineData = computed(() => {
  return store.getters.timelineData
})
const submit = () => {
  let localItemsArray = {}
  const localItems = localStorage.getItem('eventPopupFlag')
  if (localItems !== null) {
    const eventTitle = "event" + event?.value.id
    localItemsArray = JSON.parse(localItems)
    localItemsArray[eventTitle] = "Published"
    localStorage.setItem('eventPopupFlag', JSON.stringify(localItemsArray))
  }
  const submission = timelineData.value.find(item => item.name === 'Project Submission Period')
  if (submission && new Date(submission?.endAt) <= new Date()) {
    store.dispatch('setSubmissionEndError', { id: submission?.id, message: "It looks like your end date is in the past. Please update if you would like more time for submissions." })
    store.dispatch('ToggleModalGoLive')
    store.dispatch('ToggleModalSubmissionEndError')
  } else {
    store.dispatch('publishEvent', event?.value.id).then(() => {

    }).catch((error) => {
      console.error(error)
    })
    store.dispatch('ToggleModalGoLive')
    store.dispatch('ToggleModalEventLive')
  }
  // store.dispatch('ToggleModalEventBegin') TODO: hide for a now
}
</script>

<template>
  <modal-base modal-class="bg-white modal-congrats" :is-close="true">
    <template #modal-title>
      <div class="modal-congrats-title">
        <img class="modal-congrats-title-img" :src="titleImage"/>
        <h1 class="modal-congrats-title-header">Congrats!</h1>
      </div>
    </template>
    <template #modal-content>
      <div class="modal-congrats-content">
        <p class="modal-congrats-content-para">You are now able to <span class="modal-congrats-content-para-span">Launch Your Event</span>. This means that you can share your event URL, and participants can start their project submission.</p>
        <p class="modal-congrats-content-para-one">Please note: Once Launched, your <span class="modal-congrats-content-para-one-span">event URL, <br> project template and categories cannot be changed.</span></p>
      </div>
    </template>
    <template #modal-button>
      <div class="modal-congrats-buttons">
        <base-button
          id="go-live"
          name="go-live"
          text="Launch Event Now"
          aria-label="go Button"
          class-name="modal-congrats-buttons-live"
          :functions="submit"/>
        <base-button
          id="go-cancel"
          name="go-cancel"
          class-name="modal-congrats-buttons-time"
          btn-type="text"
          :functions="cancel"
          text="Stay In Draft Mode"/>
      </div>
      <button></button>
    </template>
  </modal-base>
</template>

<style lang="scss" scoped>
.modal-congrats {
  @apply md:h-auto top-auto max-w-lg pb-7 rounded-b-none md:rounded-2xl overflow-y-auto;
  &-title {
    @apply h-28 md:h-36 flex flex-col justify-center items-center;
    &-header {
      @apply md:text-h2 text-h3 mb-4 w-full text-center font-medium;
    }
    &-img {
      @apply w-full md:h-28;
    }
  }
  &-content {
    &-para {
      @apply max-w-[30rem] px-3 text-p-md text-center mx-auto md:leading-6 leading-5 text-blacks-300;
      &-span {
        @apply font-medium text-blacks-500;
      }
      &-one {
        @apply max-w-[24rem] mb-8 px-3 text-p-md text-center mx-auto md:leading-6 leading-5 text-blacks-300;
        &-span {
          @apply font-medium text-blacks-500;
        }
      }
    }
  }
  &-buttons {
    @apply md:flex justify-center px-5 md:flex-row-reverse md:mt-5 mt-3;
    &-live {
      @apply md:max-w-[52rem] md:mx-2 capitalize w-full md:mb-0 mb-3 h-11;
    }
    &-time {
      @apply text-product-500 border border-product-500 px-3 py-1.5 rounded-lg capitalize w-full md:mb-0 mb-3 h-11;
    }
  }
}
</style>
