<script setup>
import ModalBase from "@/components/Modals/ModalBase"
import BaseButton from '@/components/Base/Button/BaseButton';
import { computed } from 'vue'
import store from '@/store'

const event = computed(() => {
  return store.getters.eventBuilderEvent
})
const cancel = () => {
  store.dispatch('ToggleModalEventLaunch')
  store.dispatch('ToggleModalEventDraft')
}
const timelineData = computed(() => {
  return store.getters.timelineData
})
const submit = () => {
  const submission = timelineData.value.find(item => item.name === 'Project Submission Period')
  if (submission && new Date(submission?.endAt) <= new Date()) {
    store.dispatch('setSubmissionEndError', { id: submission?.id, message: "It looks like your end date is in the past. Please update if you would like more time for submissions." })
    store.dispatch('ToggleModalEventLaunch')
    store.dispatch('ToggleModalSubmissionEndError')
  } else {
    store.dispatch('publishEvent', event?.value.id).then(() => {
      const payload = { id: submission?.id, startAt: new Date() }
      store.dispatch('setTimelineData', payload)
    }).catch((error) => {
      console.error(error)
    })

    store.dispatch('ToggleModalEventLaunch')
    store.dispatch('ToggleModalEventLive')
  }
  // store.dispatch('ToggleModalEventBegin') TODO: hide for a now
}
</script>

<template>
  <modal-base modal-class="bg-white modal-eventLaunch" :is-close="true">
    <template #modal-title>
      <div class="modal-eventLaunch-title">
        <h1 class="modal-eventLaunch-title-header">Are You Ready To Launch Your Event?</h1>
      </div>
    </template>
    <template #modal-content>
      <div class="modal-eventLaunch-content">
        <p class="modal-eventLaunch-content-para">As a reminder, once your event is launched, your unique event URL, project template and categories cannot be changed. All other components can be modified and updated to the live site.</p>
      </div>
    </template>
    <template #modal-button>
      <div class="modal-eventLaunch-buttons">
          <base-button
            id="go-live"
            name="go-live"
            text="Launch Event Now"
            aria-label="go Button"
            class-name="modal-eventLaunch-buttons-live"
            :functions="submit"/>
          <base-button id="go-cancel"
                       name="go-cancel"
                       class-name="modal-eventLaunch-buttons-time"
                       btn-type="text"
                       :functions="cancel"
                       text="Stay In draft Mode" />
        </div>
    </template>
  </modal-base>
</template>

<style lang="scss" scoped>
.modal-eventLaunch {
  @apply md:h-auto max-w-lg md:pt-10 pb-7 px-5 md:rounded-2xl overflow-y-auto rounded-b-none;
  &-title {
    @apply mt-10 mb-8;
    &-header {
      @apply md:text-h2 text-h3 mb-8 w-full text-center font-medium;
    }
  }
  &-content {
    @apply max-w-[27rem] mx-auto;
    &-para {
      @apply max-w-[30rem] text-p-md text-center mx-auto md:leading-6 leading-5 text-blacks-500;
    }
  }
  &-buttons {
    @apply md:flex justify-center md:flex-row-reverse mt-5;
    &-live {
      @apply md:max-w-[12rem] md:mx-2 capitalize w-full md:mb-0 mb-3 h-11;
    }
    &-time {
      @apply md:max-w-[12rem] text-product-500 md:mx-2 border border-product-500 px-3 py-1.5 rounded-lg capitalize w-full md:mb-0 mb-3 h-11;
    }
  }
}
</style>
