import EventService from "@/services/event"
import { processStatuses, tracking } from "@/utils"
import { eventPlans } from '@/utils/eventPlans'

export default {
  state: () => ({
    progress_bar: 0,
    event_modal_type: 'organization',
    tier_one: [
      {
        name: 'agendaCheck',
        type: 'checkbox',
        value: '',
        ariaLabel: 'videos check',
        text: 'Easy schedule and timeline of the event.',
        label: 'Agenda',
      },
      {
        name: 'timelineCheck',
        type: 'checkbox',
        value: '',
        ariaLabel: 'timeline check',
        text: 'Easy schedule and timeline of the event.',
        label: 'Timeline',
      },
      {
        name: 'faqCheck',
        type: 'checkbox',
        value: '',
        ariaLabel: 'faq check',
        text: 'Add a block with questions and answers.',
        label: 'FAQ',
      },
      {
        name: 'sponsorsCheck',
        type: 'checkbox',
        value: '',
        ariaLabel: 'sponsors check',
        text: 'Reward activity and actively set evaluation criteria.',
        label: 'Sponsors',
      },
      {
        name: 'resourcesCheck',
        type: 'checkbox',
        value: '',
        ariaLabel: 'resources check',
        text: 'You can additional information here.',
        label: 'Resources',
      },
      {
        name: 'participants',
        type: 'select',
        data: [
          {
            value: '< 10',
            label: '< 10',
          },
          {
            value: '> 10',
            label: '> 10'
          }
        ],
        value: '',
        ariaLabel: 'participants',
        text: 'Number of participants/students*'
      },
    ],
    tier_two: [
      {
        name: 'videosCheck',
        type: 'checkbox',
        value: '',
        ariaLabel: 'videos check',
        label: 'Videos',
        text: 'tream or upload speeches and ceremonies, pitches and other public parts of the event.',
      },
      {
        name: 'judgingCheck',
        type: 'checkbox',
        value: '',
        ariaLabel: 'judging check',
        label: 'Judging',
        text: 'Easily add sponsors to the event page to highlight who supported the event.',
      },
      {
        name: 'analyticsCheck',
        type: 'checkbox',
        value: '',
        ariaLabel: 'analytics check',
        label: 'Analytics',
        text: 'Information about how the user can use this block.',
      },
      {
        name: 'awardsCheck',
        type: 'checkbox',
        value: '',
        ariaLabel: 'awards check',
        label: 'Awards',
        text: 'Information about how the user can use this block.',
      },
    ],
    events: {},
    planNameToEventsMap: {
      "professional": [],
      "essentials": [],
      "starter": [],
      "free": []
    },
    eventsStatus: processStatuses.IDLE,
    eventsError: null,
    newEvent: {},
    newEventStatus: processStatuses.IDLE,
    newEventError: {},
    eventDeleteStatus: processStatuses.IDLE,
    eventDeleteError: null,
    afterRegister: false,
    feedback: null,
    feedbackError: null,
    feedbackValidationError: {},
    createEventType: eventPlans.FREE
  }),
  getters: {
    progressBar: state => state.progress_bar,
    eventModalType: state => state.event_modal_type,
    tierOne: state => state.tier_one,
    tierTwo: state => state.tier_two,
    events: state => state.eventsStatus === processStatuses.LOADED ? Object.values(state.events) : [],
    eventsByPlanName: state => planName => {return state.planNameToEventsMap[planName]},
    eventsStatus: state => state.eventsStatus,
    eventsError: state => state.eventsError,
    newEvent: state => state.newEvent,
    newEventStatus: state => state.newEventStatus,
    newEventError: state => state.newEventError,
    afterRegister: state => state.afterRegister,
    feedback: state => state.feedback,
    feedbackError: state => state.feedbackError,
    feedbackValidationError: state => state.feedbackValidationError,
    createEventType: state => state.createEventType
  },
  mutations: {
    SET_AFTER_REGISTER (state, status) {
      state.afterRegister = status
    },
    SET_PROGRESS_BAR (state, step) {
      state.progress_bar = step
    },
    SET_EVENT_MODAL (state, modal) {
      state.event_modal_type = modal
    },
    RESET_EVENT_MODAL (state) {
      state.progress_bar = 0
      state.event_modal_type = 'organization'
      state.afterRegister = false
      state.newEvent = {}
      state.newEventError = {}
    },
    EVENT_LOADED (state, events) {
      state.eventsStatus = processStatuses.LOADED
      // Convert event array into id, event dictionary
      state.events = events.reduce((ret, event) => {
        if (event.slug != 'selfserve') {
          ret[event.id] = event
        }
        return ret
      }, {})
    },
    SET_PLAN_NAME_TO_EVENTS_MAP (state, { planName, events }) {
      state.planNameToEventsMap[planName] = events
    },
    EVENT_LOADING (state) {
      state.eventsStatus = processStatuses.LOADING
    },
    EVENT_ERROR (state, error) {
      state.eventsStatus = processStatuses.ERROR
      state.eventsError = error
    },
    NEW_EVENT_SET (state, event) {
      state.newEvent = {
        ...state.newEvent,
        ...event
      }
      state.newEventStatus = processStatuses.IDLE
      state.newEventError = null
    },
    NEW_EVENT_SAVING (state) {
      state.newEventStatus = processStatuses.SAVING
    },
    NEW_EVENT_SAVED (state, event) {
      state.newEventStatus = processStatuses.LOADED
      state.events[event.id] = event
    },
    NEW_EVENT_ERROR (state, error) {
      state.newEventStatus = processStatuses.ERROR
      state.newEventError[error.field] = error
    },
    EVENT_DELETING (state) {
      state.eventDeleteStatus = processStatuses.LOADING
    },
    EVENT_DELETED (state, eventId) {
      state.eventDeleteStatus = processStatuses.LOADED
      delete state.events[eventId]
    },
    EVENT_DELETE_ERROR (state, err) {
      state.eventDeleteStatus = processStatuses.ERROR
      state.eventDeleteError = err
    },
    RESET_NEW_EVENT (state) {
      state.newEventStatus = processStatuses.IDLE
      state.newEventError = {}
      state.newEvent = {}
    },
    EVENT_CANCEL_FEEDBACK (state, feedback) {
      state.feedback = feedback
    },
    FEEDBACK_SAVED (state) {
      state.feedback = null
    },
    FEEDBACK_SAVE_ERROR (state, error) {
      state.feedbackError = error
    },
    FEEDBACK_VALIDATION_ERROR (state, error) {
      state.feedbackValidationError[error.key] = error.errMsg
    },
    RESET_FEEDBACK_VALIDATION_ERROR (state) {
      state.feedbackValidationError = {}
      state.feedbackError = null
    },
    SET_CREATE_EVENT_TYPE (state, type) {
      state.createEventType = type
    },
    RESET_CREATE_EVENT_TYPE (state) {
      state.createEventType = eventPlans.FREE
    }
  },
  actions: {
    setAfterRegister ({ commit }, status) {
      commit ('SET_AFTER_REGISTER', status)
    },
    SetProgressBar ({ commit }, step) {
      commit ('SET_PROGRESS_BAR', step)
    },
    SetEventModal ({ commit }, modal) {
      commit ('SET_EVENT_MODAL', modal)
    },
    ResetEventModal ({ commit }) {
      commit ('RESET_EVENT_MODAL')
    },
    GetEvents ({ commit, getters, rootGetters }) {
      // Dependent on organizationID
      if (rootGetters.userStatus !== processStatuses.LOADED) {
        console.warn('User not loaded yet')
        return
      }

      // Prevent duplicate calls
      if (getters.eventsStatus !== processStatuses.LOADING) {
        commit('EVENT_LOADING')
        return new Promise((resolve, reject) => {
          EventService.getEvents({
            organizationId: rootGetters.getActiveOrganization.id,
            limit: 100,
            offset: 0,
            include: "setting,info,event_templates,features"
          }).then((response) => {
            commit('EVENT_LOADED', response.data.events)
            resolve()
          }).catch((err) => {
            commit('EVENT_ERROR', err)
            reject(err)
          })
        })
      }
    },
    setPlanNameToEventsMap ({ commit, getters }) {
      const eventIdToEventsMap = {}
      const professionalEventsMap = {}
      const essentialsEventsMap = {}
      const starterEventsMap = {}
      const freeEventsMap = {}

      for (const event of getters.events) {
        eventIdToEventsMap[event.id] = event
      }
      for (const subscription of getters.subscriptionPlans) {
        if (subscription?.plan?.name === eventPlans.PROFESSIONAL) {
          professionalEventsMap[subscription.eventId] = eventIdToEventsMap[subscription.eventId]
          delete essentialsEventsMap[subscription.eventId]
          delete starterEventsMap[subscription.eventId]
          delete freeEventsMap[subscription.eventId]
        } else if (subscription?.plan?.name === eventPlans.ESSENTIALS) {
          if (professionalEventsMap[subscription.eventId]) {
            continue
          }
          essentialsEventsMap[subscription.eventId] = eventIdToEventsMap[subscription.eventId]
          delete starterEventsMap[subscription.eventId]
          delete freeEventsMap[subscription.eventId]
        } else if (subscription?.plan?.name === eventPlans.STARTER) {
          if (professionalEventsMap[subscription.eventId] || essentialsEventsMap[subscription.eventId]) {
            continue
          }
          starterEventsMap[subscription.eventId] = eventIdToEventsMap[subscription.eventId]
          delete freeEventsMap[subscription.eventId]
        } else {
          if (professionalEventsMap[subscription.eventId] || essentialsEventsMap[subscription.eventId] || starterEventsMap[subscription.eventId]) {
            continue
          }
          freeEventsMap[subscription.eventId] = eventIdToEventsMap[subscription.eventId]
        }
      }
      commit('SET_PLAN_NAME_TO_EVENTS_MAP', { planName: eventPlans.PROFESSIONAL, events: Object.values(professionalEventsMap) })
      commit('SET_PLAN_NAME_TO_EVENTS_MAP', { planName: eventPlans.ESSENTIALS, events: Object.values(essentialsEventsMap) })
      commit('SET_PLAN_NAME_TO_EVENTS_MAP', { planName: eventPlans.STARTER, events: Object.values(starterEventsMap) })
      commit('SET_PLAN_NAME_TO_EVENTS_MAP', { planName: eventPlans.FREE, events: Object.values(freeEventsMap) })
    },
    setNewEvent({ commit }, payload) {
      commit('NEW_EVENT_SET', payload)
    },
    setNewEventSetting({ commit }, { primaryColor, secondaryColor, imagePath }) {
      return new Promise((resolve, reject) => {
        let hasError = false
        if (!primaryColor || primaryColor == '') {
          commit('NEW_EVENT_ERROR', { key: 'primaryColor', value: 'Primary color is required.'})
          hasError = true
        }

        if (!secondaryColor || secondaryColor == '') {
          commit('NEW_EVENT_ERROR', { key: 'secondaryColor', value: 'Secondary is required.'})
          hasError = true
        }

        if (!hasError) {
          commit('NEW_EVENT_SET', { primaryColor, secondaryColor, imagePath })
          resolve()
        } else {
          reject()
        }
      })

    },
    createEvent({ commit, state, rootGetters }) {
      // Dependent on organizationID
      if (rootGetters.userStatus !== processStatuses.LOADED) {
        console.warn('User not loaded yet, can not create events.')
        return
      }
      commit('NEW_EVENT_SAVING')
      return new Promise((resolve, reject) => {
        EventService.createEvent({
          name: state.newEvent.eventName,
          description: state.newEvent.description,
          primaryColor: state.newEvent.primaryColor,
          secondaryColor: state.newEvent.secondaryColor,
          imagePath: state.newEvent.imagePath,
          plan: state.createEventType,
          organizationId: rootGetters.getActiveOrganization?.id
        }).then((response) => {
          commit('NEW_EVENT_SAVED', response.data.event)
          tracking.trackEvent('event_created', {
            eventId: response.data.event.id,
            eventSlug: response.data.event.slug,
            eventName: response.data.event.name,
            eventCreatedAt: response.data.event.createdAt,
            user: { 
              id: rootGetters.user.id,
              email: rootGetters.user.email
            }
          })
          resolve(response.data.event)
        }).catch((err) => {
          console.debug(err)
          commit('NEW_EVENT_ERROR', { key: 'server', value: err.data.message })
          reject(err)
        })
      })
    },
    deleteEvent({ commit }, { eventId }) {
      commit('EVENT_DELETING')
      return new Promise((resolve, reject) => {
        EventService.deleteEvent({ eventId }).then((response) => {
          if (response.data.success) {
            commit('EVENT_DELETED', eventId)
            resolve()
          } else {
            commit('EVENT_DELETE_ERROR', response.data)
            reject()
          }
        }).catch((err) => {
          commit('EVENT_DELETE_ERROR', err.data.message)
          reject(err)
        })
      })
    },
    resetNewEvent({ commit }) {
      commit('RESET_NEW_EVENT')
    },
    eventCancelFeedback({ commit }, feedback) {
      commit('EVENT_CANCEL_FEEDBACK', feedback)
    },
    submitEventFeedback({ commit }, { eventId, userId, feedback }) {
      commit('RESET_FEEDBACK_VALIDATION_ERROR')
        return new Promise((resolve, reject) => {
            if (!feedback) {
              const payload = { 'key': 'feedback', 'errMsg': 'Please enter a feedback' }
              commit('FEEDBACK_VALIDATION_ERROR', payload)
              reject()
            }
            else {
              EventService.submitEventFeedback(eventId, userId, feedback).then((response) => {
                if (response.status == 200) {
                  commit('FEEDBACK_SAVED')
                  resolve(response.data)
                } else {
                  commit('FEEDBACK_SAVE_ERROR', response.data)
                  reject()
                }
              }).catch((err) => {
                console.error(err)
                commit('FEEDBACK_SAVE_ERROR', err.data.message)
                reject(err)
              })
            }
        })
    },
    resetFeedbackValidationError({ commit }) {
      commit('RESET_FEEDBACK_VALIDATION_ERROR')
    },
    createEventType ({ commit }, type) {
      commit('SET_CREATE_EVENT_TYPE', type)
    },
    resetCreateEventType ({ commit }) {
      commit('RESET_CREATE_EVENT_TYPE')
    }
  }
}
