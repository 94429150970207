<script setup>
import { ref, onMounted, computed } from 'vue'
import store from '@/store'
import BaseInput from '@/components/Base/Fields/InputField'
import Datepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import { convertHoursToDays, isValidDate } from '@/utils'

const props = defineProps({
  id: {
    type: String,
    required: true
  },
  position: {
    type: Number,
    required: true
  },
  modelValue: {
    type: Object,
  },
  timelineErrorMessages: {
    type: Object,
  },
  canUpdate: {
    type: Boolean,
    default: true
  }
})

const timelineError = computed(() => {
  return props.timelineErrorMessages
})

const timelineValidateError = (type) => {
  let errMsg = ''
  if(timelineError.value != null) {
    Object.keys(timelineError.value).filter(item =>{
      if (item === type) {
        errMsg = timelineError.value[type]
      }
    })
  }
  return errMsg
}

const timelineOriginalData = computed(() => {
  return store.getters.timelineOrignalData
})

const startDate = ref(new Date(new Date().setDate(new Date().getDate() + 1)))
const startTime = ref({ hours: 0, minutes: 0 })
const endStartTime = ref({ hours: 0, minutes: 0 })
const nameLabel = ref('')
const emit = defineEmits(['update:modelValue'])

// Function to format the date with timezone
const formatDate = (value) => {
  const date = new Date(value)
  const options = {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timeZoneName: 'short',
  }
  // TODO:  return new Intl.DateTimeFormat(window.navigator.languages[0], options).format(date)
  return new Intl.DateTimeFormat('en-US', options).format(date)
}

const name = computed({
  get() {
    return props.modelValue.name
  },
  set(newValue) {
    const localData = props.modelValue
    localData.name = newValue
    emit('update:modelValue', localData)
  }
})

const startAt = computed({
  get() {
    return props.modelValue.startAt
  },
  set(newValue) {
    const localData = props.modelValue
    localData.startAt = newValue
    //TODO for future changes localData.endAt = (localData.name === 'Project Submission Period' ? new Date(new Date(localData.startAt).setDate(new Date(localData.startAt).getDate() + 14)) : new Date(new Date(localData.startAt).setDate(new Date(localData.startAt).getDate() + 1)))
    // TODO localData.endAt.setHours(23,59,0,0)
    emit('update:modelValue', localData)
    timelineOriginalData.value.find(item => {
      if(item.name === localData.name && new Date(item.startAt).getTime() === new Date(localData.startAt.toISOString()).getTime()) {
        store.dispatch('setEventDataChanged', false)
      } else {
        store.dispatch('setEventDataChanged', true)
      }
    })
  }
})

const endAt = computed({
  get() {
    return props.modelValue.endAt
  },
  set(newValue) {
    const localData = props.modelValue
    localData.endAt = newValue
    emit('update:modelValue', localData)
    timelineOriginalData.value.find(item => {
      if(item?.name === localData?.name && item?.endAt && localData?.endAt && new Date(item?.endAt).getTime() === new Date(localData?.endAt.toISOString()).getTime()) {
        store.dispatch('setEventDataChanged', false)
      } else {
        store.dispatch('setEventDataChanged', true)
      }
    })
  }
})

const event = computed(() => {
  return store.getters.eventBuilderEvent
})

const timelineDuration = computed(() => {
  const eventFeatures = event.value.features
  const eventDurationFeatures = eventFeatures.filter(feature => feature.featureGroup.code === 'event_duration')
  if (eventDurationFeatures.length > 0) {
    const highestPriorityFeature = eventDurationFeatures.reduce((highest, current) => {
      return current.priority > highest.priority ? current : highest;
    })
    return convertHoursToDays(highestPriorityFeature.value)
  } else {
    return 0
  }
})

const minEndDate = computed(() => {
  const startDate = new Date(startAt.value)
  if (!isValidDate(startDate) || name.value === 'Project Submission Period') {
    const now = new Date()
    return new Date(new Date().setDate(new Date().getDate())).setHours(now.getHours(), now.getMinutes(), now.getSeconds(), 0)
  }

  if (name.value === 'Project Showcase') {
    startDate.setDate(startDate.getDate() + 1)
  }

  return startDate
})

const maxEndDate = computed(() => {
  if (!isValidDate(new Date(startAt.value)) || name.value === 'Project Submission Period') {
    return ''
  }

  if (name.value === 'Project Showcase') {
    return new Date(startAt.value).setDate(new Date(startAt.value).getDate() + (timelineDuration.value - 1))
  }

  return new Date().setDate(new Date().getDate() + 1)
})

const endStartDate = computed(() => {
  const startDate = new Date(startAt.value)

  if (!isValidDate(startDate)) {
    return new Date()
  }

  return startDate > new Date().setDate(new Date().getDate()) ? startDate : new Date().setDate(new Date().getDate() + 1)
})


const submissionsUrl = computed(() => {
  return `/event-builder/${event.value.slug}/submissions`
})

const subscriptionDetails = computed(() => {
  return store.getters.getEventSubscriptionDetails
})

const checkStartDate = () => {
  if (startAt.value !== null && name.value === 'Project Showcase') {
    const startDate = new Date(startAt.value)

    const maxEndDate = new Date(startDate) // Create a copy of startDate
    maxEndDate.setDate(startDate.getDate() + (timelineDuration.value - 1))

    if (endAt.value !== null) {
      endAt.value = new Date(endAt.value)

      if (!(startDate < endAt.value)) {
        endAt.value = startDate
      }

      if (endAt.value > maxEndDate) {
        endAt.value = maxEndDate
      }
    }
  }
}

const mandatoryName = (position) => {
  switch (position) {
  case 1:
    name.value = 'Project Submission Period'
    nameLabel.value = `This is the project submission phase for participants. After projects are submitted, visit <a href='${submissionsUrl.value}'><u>Project Submissions</u></a> to manage and approve them. We suggest having a gap between your Submission End Date and Project Showcase Start Date to allow for last-minute submissions.`
    break
  case 2:
    name.value = 'Project Showcase'
    nameLabel.value = `This is when the approved projects will be visible on your event page! Your plan gives you <span class='timelineForm-card-label-span-color'>${timelineDuration.value} DAYS</span> for your Showcase. Please choose your dates below. Once the showcase ends, all projects will still be accessible privately through each participants profiles.`
    break
  default:
    name.value = ''
    nameLabel.value = 'Period Name*'
    break
  }
}

onMounted(() => {
  mandatoryName(props.position)
})
</script>

<template>
  <div class="timelineForm">
    <form :id="id"
          class="timelineForm-card">
      <div :class="{'timelineForm-card-container' : position > 2}">
        <h1 v-if="position <= 2"
            class="timelineForm-card-title">
          {{name}}
        </h1>
        <label class="timelineForm-card-label" v-html="nameLabel"></label>
        <base-input v-if="position > 2"
                    v-model="name"
                    :model-value="name"
                    :label-text="nameLabel"
                    input-name="periodName"
                    input-type="text"
                    input-id="periodName"
                    class-name="timelineForm-card-input"
                    input-place-holder="Period name"
                    :errors="timelineValidateError('name')"/>
      </div>
      <div class="timelineForm-card-timeContainer">
        <span class="timelineForm-card-timeLeft">
          <label class="timelineForm-card-label-date">
            {{name === 'Project Showcase' ? "Start Date*" : "Start Date"}}
          </label>
          <base-input v-if="name === 'Project Submission Period'"
              class-name="-mt-1"
              :disabled="true"
              model-value="Starts when the event is launched" />
          <datepicker v-if="name === 'Project Showcase'"
                      v-model="startAt"
                      hide-offset-dates auto-apply no-today
                      :start-date="startDate"
                      :start-time="startTime"
                      :format="formatDate"
                      :week-start="0"
                      :disabled="!canUpdate"
                      :min-date="name === 'Project Submission Period' ? new Date(new Date().setDate(new Date().getDate())).setHours(0, 0, 0, 0) : new Date(new Date().setDate(new Date().getDate())).setHours(0, 0, 0, 0)"
                      @update:model-value="checkStartDate">
            <template #calendar-header="{ index, day }">
              <div :class="index === 5 || index === 6 ? 'red-color' : ''">
                {{ day }}
              </div>
            </template>
          </datepicker>
          <p class="error timelineForm-card-timeContainer-error">{{timelineValidateError('startAt')}}</p>
        </span>
        <span class="timelineForm-card-timeRight">
          <label class="timelineForm-card-label-date">
            End Date*
          </label>
          <datepicker v-model="endAt" hide-offset-dates no-today auto-apply
                      :format="formatDate"
                      :start-time="endStartTime"
                      :disabled="name === 'Project Showcase' ? !canUpdate && subscriptionDetails?.plan?.name === 'free' : false"
                      :start-date="endStartDate"
                      :min-date="minEndDate"
                      :max-date="maxEndDate"
                      :week-start="0">
            <template #calendar-header="{ index, day }">
              <div :class="index === 5 || index === 6 ? 'red-color' : ''">
                {{ day }}
              </div>
            </template>
          </datepicker>
          <p class="error timelineForm-card-timeContainer-error">{{timelineValidateError('endAt')}}</p>
        </span>
      </div>
      <span class="error">{{ !!timelineError ? timelineError.timelineServerError : "" }}</span>
    </form>
  </div>
</template>

<style lang="scss" scoped>
.timelineForm {
  @apply flex flex-row py-4;
  &-card {
    &-container {
      @apply flex flex-col pb-4;
    }
    &-label {
      @apply text-p-sm py-1 text-blacks-300 md:pr-[3rem] inline-block;
      &-span {
        :deep &-color {
          @apply text-purple-dark font-bold;
        }
      }
      &-date {
        @apply text-blacks-500 text-p-sm;
      }
    }
    &-title {
      @apply font-medium text-h4 mb-2;
    }
    &-input {
      @apply rounded-md border p-1 text-left;
    }
    &-timeContainer {
      @apply flex flex-col md:flex-row w-full pt-2;
      &-error {
        @apply font-normal text-p-sm mt-1 text-left;
      }
    }
    &-timeLeft {
      @apply md:w-1/2 md:mr-2 w-full;
    }
    &-timeRight {
      @apply md:w-1/2 md:ml-2 w-full;
    }
  }
}
:deep .dp__input {
  @apply h-11 rounded-lg text-p-md;
}
:deep .dp__disabled, :deep .field-input:disabled {
  @apply bg-background-500 text-greys-500 text-p-md h-11 opacity-50;
}
</style>
