import axios from 'axios'
import { config } from '@/config'

class ParticipantsService {
  getParticipants({ eventId }, include = 'invitation') {
    return axios.get(`${config.API_URL}/users`, {
      params: {
        'pagination.pageSize': 500,
        'pagination.nextPageToken': 0,
        roles: ["ss_event_participant"],
        include: include,
        eventId: eventId
      }
    })
  }

  fetchInvitedParticipants({ pageSize, nextPageToken, eventId }) {
    return axios.get(`${config.API_URL}/invited/users`, {
      params: {
        'pagination.pageSize': pageSize,
        'pagination.nextPageToken': nextPageToken,
        include: "user",
        eventId: eventId,
        roles: ["ss_event_participant"]
      },
      paramsSerializer: {
        indexes: null,
      }
    })
  }

  deleteInviteUser({ id }) {
    return axios.delete(`${config.API_URL}/participants/unInvite/${id}`)
  }

  deleteParticipants({ id = 0, userId, eventId, roles }) {
    return axios.delete(`${config.API_URL}/auth/user-role/${id}`, {
      params: {
        userId: userId,
        eventId: eventId,
        roles: roles
      },
      paramsSerializer: {
        indexes: null,
      }
    })
  }

  inviteParticipants({ userInvitations, eventId }) {
    return axios.post(`${config.API_URL}/participants/invite`, {
      userInvitations: userInvitations,
      eventId: eventId
    })
  }

  reInviteParticipants({ email, eventId }) {
    return axios.post(`${config.API_URL}/participants/reInvite`, {
      email: email,
      eventId: eventId
    })
  }

  searchParticipants({ pageSize, nextPageToken, filterBy, keyword, userIds, userNames, emails, include } ) {
    return axios.get(`${config.API_URL}/users/find`, {
      params: {
        pageSize: pageSize,
        nextPageToken: nextPageToken,
        filterBy: filterBy,
        keyword: keyword,
        userIds: userIds,
        userNames: userNames,
        emails: emails,
        include: include
      }
    })
  }

}
export default new ParticipantsService()

