import axios from 'axios'
import { config } from '@/config'

class OrganizationUsersService {
  getOrganizationUsers({ organizationId }) {
    const params = {
      'pagination.pageSize': 500,
      include: 'organization,user'
    }
    if (organizationId) {
      params.organizationId = organizationId
    }

    return axios.get(`${config.API_URL}/organization/users`, {  params: params})
  }

  searchUsers({ pageSize, nextPageToken, filterBy, keyword, userIds, userNames, emails, include } ) {
    return axios.get(`${config.API_URL}/users/find`, {
      params: {
        pageSize: pageSize,
        nextPageToken: nextPageToken,
        filterBy: filterBy,
        keyword: keyword,
        userIds: userIds,
        userNames: userNames,
        emails: emails,
        include: include
      }
    })
  }

  fetchInvitedUsers({ pageSize, nextPageToken, organizationId, eventId }) {
    return axios.get(`${config.API_URL}/invited/users`, {
      params: {
        'pagination.pageSize': pageSize,
        'pagination.nextPageToken': nextPageToken,
        include: "user",
        eventId: eventId,
        organizationId: organizationId,
      }
    })
  }

  inviteUsers({ userInvitations, organizationId }) {
    return axios.post(`${config.API_URL}/users/invite`, {
      userInvitations: userInvitations,
      organizationId: organizationId
    })
  }

  reInviteUser({ email, organizationId }) {
    return axios.post(`${config.API_URL}/users/reInvite`, {
      email: email,
      organizationId: organizationId
    })
  }

  deleteInviteUser({ id, organizationId }) {
    return axios.delete(`${config.API_URL}/users/unInvite/${id}`,{
      params: {
        organizationId: organizationId
      }
    })
  }

  acceptUserInvitation({ token }) {
    return axios.post(`${config.API_URL}/users/acceptUserInvitation`, {
      token: token
    })
  }

  updateOrganizationUser({ organizationUserRole, id }) {
    const body = {
      organizationUserRole: organizationUserRole
    }

    return axios.patch(`${config.API_URL}/organization/users/${id}`, body)
  }

  deleteOrganizationUser({ id }) {
    return axios.delete(`${config.API_URL}/organization/users/${id}`)
  }

}

export default new OrganizationUsersService()
