<script setup>
// Required Component
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
// Store
import store from '@/store'
// Layouts
import ModalBase from '@/components/Modals/ModalBase'
// Custom Components
import PricingPlan from '@/components/Modals/Plan/Pricing'
// Utils
import { processStatuses, eventPlans, eventUpgradeMapping } from '@/utils'
import { config } from '@/config'

const route = useRoute()

const selectPlanIndex = ref(0)

// ToDo this should come from the Plans
const includedList = [
  {
    name: 'starter',
    icon: 'https://firebasestorage.googleapis.com/v0/b/project-leo-mvp.appspot.com/o/SelfServe%2Fplans%2Fprojects.svg?alt=media&token=716dfe3e-34e0-438e-b4ab-69753be69d9c',
    description: 'For growing showcases wanting to celebrate achievements with awards.',
    items: [
      '2 Admins',
      '100 Participants/Showcase',
      'Awards & Badges',
      'Plagiarism/Similarity Scanner'
    ]
  },
  {
    name: 'essentials',
    icon: 'https://firebasestorage.googleapis.com/v0/b/project-leo-mvp.appspot.com/o/SelfServe%2Fplans%2Fstar.svg?alt=media&token=3d7c0525-3b09-42a2-8fa1-36b0f9b06d22',
    description: 'For larger teams wanting categorized showcases.',
    items: [
      '5 Admins',
      '200 Participants/Showcase',
      'Awards & Badges',
      'Project Categories',
      'Plagiarism/Similarity Scanner'
    ]
  },
  {
    name: 'professional',
    icon: 'https://firebasestorage.googleapis.com/v0/b/project-leo-mvp.appspot.com/o/SelfServe%2Fplans%2Fstarship.svg?alt=media&token=53030ef3-2370-46a6-9447-b37dcb75ce3d',
    description: 'All the features for a celebration of young innovators and their projects.',
    items: [
      '10 Admins',
      '500 Participants/Showcase',
      'Awards & Badges',
      'Project Categories',
      'Plagiarism/Similarity Scanner',
      'Private Showcases',
      'Personalized Support'
    ]
  }
]

const paidPlans = computed(() => {
  let plans = store.getters.getPlans.filter(m => m.price > 0 && plansToShow.value.includes(m.name))
  if (route.query.plan && store.getters.typeOfModal === 'plan') {
    return plans.some(m => m.name === route.query.plan) ?
      plans.filter(m => m.name === route.query.plan) :
      plans
  }

  if (selectedPlan.value) {
    return plans.filter(m => m.name === selectedPlan.value)
  }

  const currentPlanObject = store.getters.getPlans.find(m => m.name === currentPlan.value)

  return plans.map(m => {
    // Calculate discount to upgrade from one paid plan to another
    if (m.name !== eventPlans.FREE) {
      return {
        ...m,
        price: m.price - (currentPlanObject?.price ?? 0)
      }
    }
    return m
  })
})

const isCategory = computed(() => {
  return route.name === 'Categories'
})

const plansToShow = computed(() => {
  return eventUpgradeMapping[currentPlan.value]
})

const currentPlan = computed(() => {
  return store.getters.modalData?.data?.currentPlan || eventPlans.FREE
})

const selectedPlan = computed(() => {
  return store.getters.modalData?.data?.selectedPlan
})

const modalType = computed(() => {
  return store.getters.typeOfModal
})

const isPlan = computed(() => {
  return store.getters.typeOfModal === 'plan'
})

const planStatus = computed(() => {
  return store.getters.getPlanStatus
})

const organization = computed(() => {
  return store.getters.newOrganization
})

const organizationStatus = computed(() => {
  return store.getters.getOrganizationStatus
})

const organizationsStatus = computed(() => {
  return store.getters.getUserOrganizationStatus
})

const user = computed(() => {
  return store.getters.user
})

const checkoutSessionStatus = computed(() => {
  return store.getters.getCheckoutSessionStatus
})

const createCheckoutSessionAndRedirect = (planId, isEducator, eventId) => {
  const fullPath = window.location.href
  // Remove query and hash from the fullPath
  const pathWithoutQueryAndHash = fullPath.split('?')[0].split('#')[0]

  store.dispatch('createCheckoutSession', {
    planId: planId,
    organizationId: organization.value.id,
    isEducation: isEducator,
    eventId: eventId ,
    successUrl: eventId && eventId > 0 ? pathWithoutQueryAndHash + '?success=true' : "",
    cancelUrl: eventId && eventId > 0 ? pathWithoutQueryAndHash + '?canceled=true' : ""
  }).then(() => {
    window.open(store.getters.getCheckoutSessionUrl, '_self')
  })
}

const isPlanLoading = computed(() => {
  return planStatus.value === processStatuses.LOADING
})

const isPlanError = computed(() => {
  return planStatus.value === processStatuses.ERROR
})

const getIncludedInfo = (included, type) => {
  return included.filter(m => m.name === type)
}

const cancelPlan = () => {
  store.dispatch('ToggleModal')
  store.dispatch('ResetModal')
}

const selectPlan = (index) => {
  selectPlanIndex.value = index
}

const getPopularPlan = (plan, type ='essentials') => {
  return plan.name === type.toLowerCase()
}

onMounted(() => {
  store.dispatch('fetchPlans')
  if (organizationsStatus.value === processStatuses.LOADED && organizationStatus.value !== processStatuses.LOADED && user.value) {
    store.dispatch('fetchOrganization', {slug: user.value.userName, userId: user.value.id})
  }
})
</script>

<template>
  <modal-base :modal-class="`${isPlan ? 'modal-plan' : `modal-upgrade ${paidPlans?.length > 1 ? ''
  : 'modal-upgrade-single'}`}`"
              :is-close="isPlan"
              :is-loading="isPlanLoading">
    <template v-if="!isPlanLoading && !isPlanError"
              #modal-title>
      <h1 class="modal-plan-title"
          :class="{
            'text-h3 -mt-4' : isPlan,
            'text-h1' : !isPlan
          }">
        {{isPlan ? `Purchase ${paidPlans[0]?.name === eventPlans.ESSENTIALS ? 'an' : 'a'} ${isCategory ? paidPlans[1]?.name : paidPlans[0]?.name} plan` :
          `Upgrade Plan${paidPlans?.length > 1 ? 's' : ''}`}}
      </h1>
    </template>
    <template #modal-content>
      <pricing-plan v-if="modalType === 'plan'"
                    :included-list="includedList"
                    :paid-plans="isCategory ? paidPlans[1] : paidPlans[0]"
                    :is-plan-loading="isPlanLoading"
                    :is-plan-error="isPlanError"
                    :cancel-plan="cancelPlan"
                    :checkout-session-status="checkoutSessionStatus"
                    :config="config"
                    :get-included-info="getIncludedInfo"
                    :process-statuses="processStatuses"
                    :create-checkout-session-and-redirect="createCheckoutSessionAndRedirect"
                    :is-plan="isPlan"/>
      <div v-if="modalType === 'upgrade'"
           class="modal-upgrade-container"
           :class="{
            'modal-upgrade-container-three-column': paidPlans && paidPlans.length > 2,
            'modal-upgrade-container-two-column': paidPlans && paidPlans.length === 2
            }">
          <div v-for="(paidPlan, index) in paidPlans"
               :key="paidPlan.id"
               class="modal-upgrade-cards"
               :class="{'modal-upgrade-cards-hide': selectPlanIndex !== index}">
            <pricing-plan :included-list="includedList"
                          :paid-plans="paidPlan"
                          :is-plan-loading="isPlanLoading"
                          :is-plan-error="isPlanError"
                          :cancel-plan="cancelPlan"
                          :checkout-session-status="checkoutSessionStatus"
                          :config="config"
                          :get-included-info="getIncludedInfo"
                          :process-statuses="processStatuses"
                          :create-checkout-session-and-redirect="createCheckoutSessionAndRedirect"
                          :is-popular-plan="getPopularPlan(paidPlan, 'essentials')"
                          :is-plan="isPlan"/>
          </div>
          <div class="modal-mobile-select">
            <span v-for="index in paidPlans?.length"
                  :key="index"
                  class="modal-mobile-select-circle"
                  :class="{
                    'modal-mobile-select-circle-active': selectPlanIndex === index - 1,
                    'hidden': paidPlans?.length === 1
                  }"
                  @click.prevent="selectPlan(index - 1)" />
          </div>
      </div>
    </template>
  </modal-base>
</template>

<style lang="scss" scoped>
.modal {
  @apply max-w-[90%];
  &-plan {
    @apply md:w-[24rem] bg-white md:px-8 px-4 py-4;
    &-title {
      @apply text-blacks-500 w-full text-center font-medium capitalize py-8;
    }
  }
  &-upgrade {
    @apply bg-white sm:max-w-[100%] w-auto;
    &-single {
      @apply max-w-[33rem] block;
    }
    &-container {
      @apply flex flex-row w-full justify-center pb-16 md:pb-12 px-4 relative items-start md:grid;
      &-two-column {
        @apply md:grid-cols-2 md:w-[42rem];
      }
      &-three-column {
        @apply md:grid-cols-3 gap-3 md:w-[60rem];
      }
    }
    &-cards {
      @apply w-[18rem] mx-auto border rounded-xl p-6 h-full max-h-[37rem] flex flex-col justify-between border-blacks-300;
      &-hide {
        @apply hidden md:flex;
      }
    }
  }
  &-mobile {
    &-select {
      @apply md:hidden fixed bottom-0 py-2 flex flex-row w-full items-center justify-center pb-8;
      &-circle {
        @apply w-6 h-6 border-product-400 border-4 cursor-pointer rounded-full mx-4;
        &-active {
          @apply bg-product-400;
        }
      }
    }
  }
}
</style>
