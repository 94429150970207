<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import { useRouter, useRoute, onBeforeRouteLeave } from 'vue-router'
// Config
import { config } from '@/config'
// Store
import store from '@/store'
// Layout
import EventPageBase from './EventPageBase'
// Base Components
import BasicForm from '@/components/Base/Forms/BasicForm'
import BaseInput from '@/components/Base/Fields/InputField'
import BaseButton from '@/components/Base/Button/BaseButton'
import TextareaField from '@/components/Base/Fields/TextareaField'
import { pbDropdown } from 'pb-components'
// Util
import { processStatuses, eventStatuses, eventPrivacySettings } from '@/utils'

const maxCharName = 80
const maxCharDescription = 450
const router = useRouter()
const route = useRoute()
let copied = ref(false)

const privacyLock = [
  {
    id: '1',
    icon: 'lock',
    content: 'Your showcase is set to Public, and can be viewed by anyone with the showcase URL. To control the showcase privacy settings please upgrade to the <u><strong>Professional Plan</strong></u>.',
    method: () => toggleUpgrade()
  }
]

const privacyContent = [
  {
    id: '1',
    icon: 'earth-americas',
    content: '<strong>Public</strong> | Anyone can view the showcase',
    value: eventPrivacySettings.PUBLIC
  },
  {
    id: '2',
    icon: 'eye-slash',
    content: '<strong>Restricted</strong> | Only admins and invited participants can view the showcase',
    value: eventPrivacySettings.PRIVATE
  }
]

const eventId = computed(() => {
  return store.getters.ebEventId
})

const eventSubscriptionDetail = computed(() => {
  // Get current event subscription plan
  // In Event Builder page event subscription details will be loaded
  let subscriptionPlan = store.getters.getEventSubscriptionDetails
  if (!subscriptionPlan || !subscriptionPlan.id) {
    // In Events listing page, we need to get the subscription plan from Plans store
    subscriptionPlan = store.getters.getSubscriptionByEventId(eventId.value)
  }

  return subscriptionPlan
})

const getPrivacyContent = () => {
  const status = event.value.eventSettings?.status || eventStatuses.DRAFT
  return isEventPrivacySettingEnabled.value || status !== eventStatuses.DRAFT ? privacyContent : privacyLock
}

const toggleUpgrade = () => {
  if (store.getters.hasRemainingPaidEvents(eventSubscriptionDetail.value?.plan?.name)) {
    store.dispatch('ToggleModalUpgradeEvent', { eventId: store.getters.ebEventId })
  } else {
    // If we are upgrading from Basic to Premium, give 250 discount
    let discount = null
    if (eventSubscriptionDetail.value?.plan?.name === 'basic') {
      discount = {
        name: 'premium',
        amount: -250
      }
    }
    store.dispatch('ToggleModalUpgradePayment', {
      eventId: store.getters.ebEventId,
      currentPlan: eventSubscriptionDetail.value?.plan?.name,
      discount: discount
    })
  }
}

// Update here
const saveGeneralSettings = () => {
  const payload = {
    eventId: event.value.id,
    name: event.value.name,
    description: event.value.eventSettings.description,
    slug: event.value.slug,
    privacy: event.value.eventSettings.privacy
  }
  store.dispatch('saveGeneralSettings', payload).then(() => {
    store.dispatch('triggerToast', { message: 'Your event has been successfully saved.', type: 'success' }).then(() => {
      if (route.params.slug !== event.value.slug) {
        router.push({ name: 'General', params: { slug: event.value.slug } })
      }
    })
  }).catch((err) => {
    if (err !== undefined) {
      store.dispatch('triggerToast', { message: 'Your event has not been successfully saved.', type: 'error' })
    }
  })
}

const event = computed(() => {
  return store.getters.eventBuilderEvent
})

const isEventDataChanged = computed(() => {
  return store.getters.isEventDataChanged
})

const eventStatus = computed(() => {
  return store.getters.ebEventStatus
})


const isGeneralSettingSaving = computed(() => {
  return store.getters.eventSettingsStatus === processStatuses.SAVING
})

const eventReady = computed(() => {
  return eventStatus.value === processStatuses.LOADED || eventStatus.value === processStatuses.SAVING
})

const isEventPublished = computed(() => {
  return event.value.eventSettings?.status === eventStatuses.LIVE
})

const isEventCodeEnabled = computed(() => {
  return store.getters.eventBuilderEvent.features.some(m => m.featureGroup?.code === 'can_manage_access_code')
})

const isEventPrivacySettingEnabled = computed(() => {
  return store.getters.eventBuilderEvent.features.some(m => m.featureGroup?.code === 'can_manage_event_privacy')
})

const originalEvent = computed(() => {
  return store.getters.events.find(event => event.id === store.getters.eventBuilderEvent.id)
})

const originalEventSettings = computed(() => {
  return store.getters.events.find(event => event.id === store.getters.eventBuilderEvent.id).eventSettings
})

const copyToClipboard = (code) => {
  navigator.clipboard.writeText(code)
  copied.value = true
}

const generalSettingErrorMessage = computed(() => {
  return store.getters.generalSettingValidationError
})

const generalSettingValidateError = (type) => {
  let temp = ''
  Object.keys(generalSettingErrorMessage.value).filter(item =>{
    if (item === type) {
      temp = generalSettingErrorMessage.value[type]
    }
  })
  return temp
}

const nextStep = () => {
  router.push({ name:'Branding', params: { slug: event.value.slug } })
  window.scrollTo(0, 0)
}

watch(
  () => ({
    name: event.value?.name,
    description: event.value?.eventSettings?.description,
    slug: event.value?.slug,
    eventCode: event.value?.eventSettings?.eventCode,
    privacy: event.value?.eventSettings?.privacy
  }),
  (newValues, oldValues) => {
    if (!originalEvent.value || !originalEventSettings.value) return

    const fields = ['name', 'description', 'slug', 'eventCode', 'privacy']
    if (fields.some(field =>
      newValues[field] !== oldValues[field] &&
      (oldValues[field] !== undefined || oldValues[field] !== null) &&
      (newValues[field] !== originalEvent.value[field] && newValues[field] !== originalEventSettings.value[field])
    )) {
      store.dispatch('setEventDataChanged', true)
    } else {
      store.dispatch('setEventDataChanged', false)
    }
  },
  { deep: true }
)

const resetEventData = () => {
  event.value.name = originalEvent.value.name
  event.value.eventSettings.description = originalEventSettings.value.description
  event.value.slug = originalEvent.value.slug
  event.value.eventSettings.privacy = originalEventSettings.value.privacy
  event.value.eventSettings.eventCode = originalEventSettings.value.eventCode
}

onMounted(() => {
  if (store.getters.ebEventStatus !== processStatuses.LOADED) {
    const slug = route?.params?.slug
    if (slug) {
      store.dispatch('getEvent', slug)
    }
  }
})

onBeforeRouteLeave((to, from, next) => {
  if(isEventDataChanged.value){
    const payload = {
      confirmAction: () => {
        resetEventData()
        store.dispatch('ResetModal')
        next()
      },
      cancelAction: () => {
        store.dispatch('ResetModal')
      }
    }
    store.dispatch('setEventDataChanged', false)
    store.dispatch('ToggleModalUnsavedAlert', payload)
  } else {
    next()
  }
})
</script>

<template>
  <event-page-base :is-published="isEventPublished">
    <template #title>
      General
    </template>
    <template #description>
      This is where you establish the name of your event, the url where the event will live, and a
      brief description that will be shown in the top banner of your event page.
    </template>

    <template #content>
      <div v-if="eventStatus === processStatuses.ERROR">
        Error to load general data. Please refresh the page.
      </div>
      <div v-else-if="eventReady"
           class="container">
        <basic-form class-name="form"
                    :submit-action="saveGeneralSettings">
          <span class="form-section">
            <base-input v-model="event.name"
                        :model-value="event.name"
                        label-text="Event Name*"
                        input-name="eventName"
                        input-id="eventName"
                        class-name="w-full !text-left"
                        input-type="text"
                        :disabled="isEventPublished"
                        :input-max-length="maxCharName"
                        :errors="generalSettingValidateError('eventName')"/>
            <span v-if="event.name && event.name.length" class="text-xs font-lighter">Character left: {{ maxCharName - (event?.name.length ?? 0) }}</span>
          </span>
          <span class="form-section">
            <textarea-field v-if="event.eventSettings"
                            v-model="event.eventSettings.description"
                            input-id="eventDescription"
                            class-name="border-grey-200"
                            input-name="eventDescription"
                            :model-value="event.eventSettings.description"
                            :disabled="isEventPublished"
                            :input-rows="5"
                            :input-resize="true"
                            :max-length="maxCharDescription"
                            label-text="Event Description*"
                            :errors="generalSettingValidateError('eventDescription')"/>
            <span v-if="event.eventSettings && event.eventSettings.description && event.eventSettings.description.length" class="text-xs font-lighter">Character left: {{ maxCharDescription - (event.eventSettings?.description.length ?? 0) }}</span>
          </span>
          <span class="form-section">
            <div class="flex flex-row justify-between">
              <label class="form-section-label">Event URL*</label>
              <base-button v-if="isEventPublished"
                            btn-type="text"
                            :functions="() => copyToClipboard(`${config.EVENT_URL}/${event.slug}`)"
                            text="Copy Code">
                            <template #contents>
                              <span class="normal-case text-p-xs font-medium">
                                <i v-if="!copied" class="fa-sm fa-regular fa-copy"></i><span v-if="!copied"> Copy Url</span>
                                <i v-if="copied" class="fa-regular fa-check"></i><span v-if="copied"> Copied</span>
                              </span>
                            </template>
              </base-button>
            </div>
            <div v-if="!isEventPublished" class="form-section-url">
              <span class="form-section-url-domain">{{ config.EVENT_URL }}/</span>
              <span class="form-section-url-slug">
                <base-input v-model="event.slug"
                            :model-value="event.slug"
                            input-type="text"
                            input-name="eventUrl"
                            input-id="eventUrl"
                            class-name="w-full !text-left"
                            :errors="generalSettingValidateError('eventUrl')"/>
                </span>
             </div>
            <div v-if="isEventPublished" class="form-section-url">
              <span class="form-section-url-slug">
                <base-input input-type="text"
                            input-name="eventUrl"
                            input-id="eventUrl"
                            class-name="w-full !text-left"
                            :model-value="config.EVENT_URL + '/' + event.slug"
                            :disabled="true"
                            :errors="generalSettingValidateError('eventUrl')"/>
              </span>
            </div>
          </span>
          <span v-if="isEventCodeEnabled" class="form-section">
            <label class="form-section-label font-bold">Unique Event Code</label>
            <div class="form-section-code">
              <div class="form-section-code-description">
                <template v-if="!isEventPublished">
                  <span class="form-section-code-description-span">The code gets generated once the event goes live. Your event will be private till then.</span>
                  <span class="form-section-code-description-span">Share this code with the Participants.</span>
                </template>
                <template v-else>
                  <span>Share this Code and the Event URL with</span>
                  <span>your participants when the Event is Live.</span>
                </template>
              </div>
              <span class="form-section-code-input">
                <template v-if="isEventPublished">
                  <span>
                    <input v-model="event.eventSettings.eventCode"
                        class="published form-section-code-input-published"
                        type="text" disabled/>
                  </span>
                  <span>
                    <base-button btn-type="text"
                                  :functions="() => copyToClipboard(event.eventSettings.eventCode)"
                                  text="Copy Code">
                                  <template #contents>
                                    <span class="normal-case text-xs font-medium">
                                      <i class="fa-sm fa-regular fa-copy"></i>
                                      Copy Code
                                    </span>
                                  </template>
                    </base-button>
                  </span>
                </template>
                <template v-else>
                  <input placeholder="______"
                        class="draft form-section-code-input-draft"
                       type="text" disabled/>
                </template>
               </span>
             </div>
            <div class="error">{{generalSettingValidateError['serverError'] ?? ''}}</div>
            <div class="error">{{generalSettingErrorMessage['serverError'] ?? ''}}</div>
          </span>
          <span class="form-section">
            <label class="form-section-label font-bold">Showcase Privacy</label>
            <pb-dropdown v-model="event.eventSettings.privacy"
                         :menu-items="getPrivacyContent()"
                         :is-disabled="isEventPublished"/>
          </span>
        </basic-form>
      </div>
    </template>
    <template #bottom-nav-container>
      <base-button id="bottom-nav-next"
                   name="bottom-nav-next"
                   class-name="bottom-nav-button"
                   btn-type="text"
                   :functions="nextStep"
                   :text="$t('eventBottomNav.next')">
        <template #contents>
          <span class="bottom-nav-button-text">
            <span class="bottom-nav-circle">
              <i class="fa-solid fa-chevron-right" />
            </span>
            {{ $t('eventBottomNav.next') }}
          </span>
        </template>
      </base-button>
      <base-button :text="$t('eventBottomNav.updateBtn')"
                   aria-label="Update Button"
                   class-name="form-button"
                   :disabled="isGeneralSettingSaving || isEventPublished"
                   :is-loading="isGeneralSettingSaving"
                   :functions="saveGeneralSettings" />
    </template>
  </event-page-base>
</template>

<style lang="scss" scoped>
.form {
  @apply bg-white rounded-2xl p-2 drop-shadow-md mt-8 mb-[6.5rem] px-8 py-4 w-full max-w-[50rem];
  &-section {
    @apply flex flex-col w-full py-4;
    &-label {
      @apply text-gray-500 text-sm pb-2 tracking-wide;
    }
    &-url {
      @apply inline-flex justify-between space-x-4;
      &-domain {
        @apply flex flex-wrap pt-[1rem] whitespace-nowrap;
      }
      &-slug {
        @apply w-full;
      }
    }
    &-code {
      @apply inline-flex justify-between space-x-4;
      &-description {
        &-span {
          @apply block;
        }
      }
      &-input {
        @apply flex flex-col items-end;
        &-published {
          @apply text-[2rem] py-1 px-4 w-[10.5rem] border rounded-md focus:outline-purple focus:outline focus:outline-2 tracking-widest font-bold;
        }
        &-draft {
          @apply w-[9rem]
        }
      }
    }
  }
  &-button {
    @apply capitalize px-6 py-2 mt-1;
  }
}
</style>
