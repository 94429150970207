const defaultModalState = () => ({
  modal: false,
  typeOfModal: '',
  eventId: '',
  modalData: {
    data: null,
    confirmAction: () => {},
    cancelAction: () => {}
  }
})

export default {
  state: defaultModalState,
  getters: {
    modal: state => state.modal,
    typeOfModal: state => state.typeOfModal,
    modalData: state => state.modalData
  },
  mutations: {
    TOGGLE_MODAL(state) {
      state.modal = !state.modal
    },
    SHOW_MODAL(state) {
      state.modal = true
    },
    CLOSE_MODAL(state) {
      state.modal = false
    },
    SET_MODAL_DATA (state, { data, confirmAction, cancelAction }) {
      state.modalData.data = data
      state.modalData.confirmAction = confirmAction
      state.modalData.cancelAction = cancelAction
    },
    SELECT_MODAL_TYPE(state, type) {
      state.typeOfModal = type
    },
    RESET_MODAL(state) {
      Object.assign(state, defaultModalState())
    }
  },
  actions: {
    // ToDo Remove this in the future, and use ShowModal/CloseModal instead
    ToggleModal({commit}) {
      commit('TOGGLE_MODAL')
    },
    // Added show modal and close modal because ToggleModal doesn't work under a racing condition
    // and raises the unpreditable results.
    // E.g. Redirection from YSC pricing page causes multi triggers of ToggleModal which causes inconsistent results
    ShowModal({commit}) {
      commit('SHOW_MODAL')
    },
    CloseModal({commit}) {
      commit('CLOSE_MODAL')
    },
    ToggleModalLogin({commit}) {
      commit('RESET_VALIDATION_ERRORS')
      commit('TOGGLE_MODAL')
      commit('TOGGLE_OVERLAY', null, {root: true})
      commit('SELECT_MODAL_TYPE', 'login')
    },
    ToggleModalEvent({commit}) {
      commit('TOGGLE_MODAL')
      commit('TOGGLE_OVERLAY', null, {root: true})
      commit('SELECT_MODAL_TYPE', 'event')
    },
    ToggleModalPlan({commit}, { plansToSkip = [] }) {
      commit('TOGGLE_MODAL')
      commit('TOGGLE_OVERLAY', null, {root: true})
      commit('SELECT_MODAL_TYPE', 'plan')
      commit('SET_MODAL_DATA', { data: { plansToSkip } })
    },
    ChangeModalType({commit}, type) {
      commit('RESET_VALIDATION_ERRORS')
      commit('SELECT_MODAL_TYPE', type)
    },
    ResetModal({commit}) {
      commit('RESET_VALIDATION_ERRORS')
      commit('RESET_MODAL')

    },
    ToggleLoginModal({commit}) {
      commit('TOGGLE_MODAL')
      commit('SELECT_MODAL_TYPE', 'login')
      commit('TOGGLE_OVERLAY', null, {root: true})
    },
    ToggleLogoutModal({commit}) {
      commit('TOGGLE_MODAL')
      commit('SELECT_MODAL_TYPE', 'logout')
      commit('TOGGLE_OVERLAY', null, {root: true})
    },
    ToggleModalSupport ({ commit }) {
      commit ('TOGGLE_MODAL')
      commit ('TOGGLE_OVERLAY', null, {root: true})
      commit ('SELECT_MODAL_TYPE', 'support')
    },
    ToggleModalVideo ({ commit }) {
      commit ('TOGGLE_MODAL')
      commit ('TOGGLE_OVERLAY', null, {root: true})
      commit ('SELECT_MODAL_TYPE', 'video')
    },
    // modal for cancel event confirmation
    ToggleModalEventCancelConfirmation({commit}, { eventId, confirmAction, cancelAction, title, description, eventCancelFeedback, eventTeamResponseTime, confirmButton, cancelButton, type }) {
      commit('TOGGLE_MODAL')
      commit('SELECT_MODAL_TYPE', type)
      commit('TOGGLE_OVERLAY', null, {root: true})
      commit('SET_MODAL_DATA', {
        data: {
          eventId,
          title,
          description,
          eventCancelFeedback,
          eventTeamResponseTime,
          confirmButton,
          cancelButton,
        },
        confirmAction,
        cancelAction,
      })
    },
    ToggleModalEventDeleteConfirmation({commit}, { eventId, confirmAction, cancelAction, title, description, confirmButton, cancelButton, type }) {
      commit('TOGGLE_MODAL')
      commit('SELECT_MODAL_TYPE', type)
      commit('TOGGLE_OVERLAY', null, {root: true})
      commit('SET_MODAL_DATA', {
        data: {
          eventId,
          title,
          description,
          confirmButton,
          cancelButton,
        },
        confirmAction,
        cancelAction,
      })
    },
    ToggleModalTemplateConfirmDelete({commit}, { eventId, confirmAction, cancelAction, title, description, confirmButton, cancelButton, type }) {
      commit('TOGGLE_MODAL')
      commit('SELECT_MODAL_TYPE', type)
      commit('TOGGLE_OVERLAY', null, {root: true})
      commit('SET_MODAL_DATA', {
        data: {
          eventId,
          title,
          description,
          confirmButton,
          cancelButton,
        },
        confirmAction,
        cancelAction,
      })
    },
    ConfirmAction({state}, payload) {
      state.modalData.confirmAction(payload)
    },
    CancelAction({state}) {
      state.modalData.cancelAction()
    },
    ToggleModalResource({commit}) {
      commit('TOGGLE_MODAL')
      commit('TOGGLE_OVERLAY', null, {root: true})
      commit('SELECT_MODAL_TYPE', 'resource')
      commit('SET_MODAL_DATA', {
        data: {
          disableOverlayClose: true
        }
      })
    },
    ToggleModalResourceDelete({commit}, {resourceId, confirmAction, cancelAction, confirmButton, cancelButton}) {
      commit('TOGGLE_MODAL')
      commit('TOGGLE_OVERLAY', null, {root: true})
      commit('SELECT_MODAL_TYPE', 'resource_delete')
      commit('SET_MODAL_DATA', {
        data: {
          resourceId,
          confirmButton,
          cancelButton,
        },
        confirmAction,
        cancelAction,
      })
    },
    ToggleModalGoLive({commit}) {
      commit('TOGGLE_MODAL')
      commit('TOGGLE_OVERLAY', null, {root: true})
      commit('SELECT_MODAL_TYPE', 'goLive')
    },
    ToggleModalEventBegin({commit}) {
      commit('TOGGLE_MODAL')
      commit('TOGGLE_OVERLAY', null, {root: true})
      commit('SELECT_MODAL_TYPE', 'eventBegin')
    },
    ToggleModalEventLive({commit}) {
      commit('TOGGLE_MODAL')
      commit('TOGGLE_OVERLAY', null, {root: true})
      commit('SELECT_MODAL_TYPE', 'eventLive')
    },
    ToggleModalResourcePreview({commit}, {resourceUrl, previewType, metadata}) {
      commit('TOGGLE_MODAL')
      commit('TOGGLE_OVERLAY', null, {root: true})
      commit('SELECT_MODAL_TYPE', 'resourcePreview')
      commit('SET_MODAL_DATA', { data: { resourceUrl, previewType, metadata } })
    },
    ToggleModalEventCancelled({commit}) {
      commit('TOGGLE_MODAL')
      commit('SELECT_MODAL_TYPE', 'eventCancelled')
      commit('TOGGLE_OVERLAY', true)
    },
    ToggleModalEventDraft({commit}) {
      commit('TOGGLE_MODAL')
      commit('TOGGLE_OVERLAY', null, {root: true})
      commit('SELECT_MODAL_TYPE', 'eventDraft')
    },
    ToggleModalEventLaunch({commit}) {
      commit('TOGGLE_MODAL')
      commit('TOGGLE_OVERLAY', null, {root: true})
      commit('SELECT_MODAL_TYPE', 'eventLaunch')
    },
    ToggleModalSubmissionEndError({commit}) {
      commit('TOGGLE_MODAL')
      commit('TOGGLE_OVERLAY', null, {root: true})
      commit('SELECT_MODAL_TYPE', 'eventSubmissionEndError')
    },
    ToggleModalUpgradePayment({commit}, { eventId, plansToSkip = [], currentPlan = "", discount = {}, selectedPlan = "" }) {
      commit('SELECT_MODAL_TYPE', 'upgrade')
      commit('TOGGLE_MODAL')
      commit('SET_MODAL_DATA', { data: { eventId, plansToSkip, discount, currentPlan, selectedPlan } })
    },
    ToggleModalUpgradeEvent({commit}, { eventId, selectedPlan }) {
      commit('SELECT_MODAL_TYPE', 'eventUpgrade')
      commit('TOGGLE_MODAL')
      commit('SET_MODAL_DATA', { data: { eventId, selectedPlan } })
    },
    ToggleModalPaymentReceipt({commit}, { receiptUrl, previewType }) {
      commit('TOGGLE_MODAL')
      commit('TOGGLE_OVERLAY', null, {root: true})
      commit('SELECT_MODAL_TYPE', 'paymentReceipt')
      commit('SET_MODAL_DATA', { data: { receiptUrl, previewType } })
    },
    ToggleModalInviteAdmin({commit}) {
      commit('TOGGLE_MODAL')
      commit('TOGGLE_OVERLAY', null, {root: true})
      commit('SELECT_MODAL_TYPE', 'inviteUser')
    },
    ToggleModalInviteParticipants({commit}) {
      commit('TOGGLE_MODAL')
      commit('TOGGLE_OVERLAY', null, {root: true})
      commit('SELECT_MODAL_TYPE', 'inviteParticipants')
    },
    ToggleModalUnsavedAlert({commit}, { confirmAction, cancelAction }) {
      commit('TOGGLE_MODAL')
      commit('SELECT_MODAL_TYPE', 'unsavedAlert')
      commit('TOGGLE_OVERLAY', true)
      commit('SET_MODAL_DATA', {
        data: {},
        confirmAction,
        cancelAction,
      })
    },
    ToggleModalCategoriesLibrary({commit}) {
      commit('TOGGLE_MODAL')
      commit('SELECT_MODAL_TYPE', 'categoriesLibrary')
      commit('TOGGLE_OVERLAY', null, {root: true})
    },
    SetLoginError({commit}, { error }) {
      commit('SET_LOGIN_ERROR', error)
    },
    ToggleInviteAdminPreview({commit}) {
      commit('TOGGLE_MODAL')
      commit('TOGGLE_OVERLAY', null, {root: true})
      commit('SELECT_MODAL_TYPE', 'inviteAdminEmailPreview')
    },
    ToggleModalInviteParticipantsPreview({commit}) {
        commit('TOGGLE_MODAL')
        commit('TOGGLE_OVERLAY', null, {root: true})
        commit('SELECT_MODAL_TYPE', 'inviteParticipantsEmailPreview')
    },
    ToggleModalTransferOwnership({commit}, { userId, confirmAction, cancelAction, title, description, confirmButton, cancelButton, type }) {
      commit('TOGGLE_MODAL')
      commit('SELECT_MODAL_TYPE', type)
      commit('TOGGLE_OVERLAY', null, {root: true})
      commit('SET_MODAL_DATA', {
        data: {
          userId,
          title,
          description,
          confirmButton,
          cancelButton,
        },
        confirmAction,
        cancelAction,
      })
    },
    ToggleModalRemoveAccess({commit}, { userId, confirmAction, cancelAction, title, description, confirmButton, cancelButton, type }) {
      commit('TOGGLE_MODAL')
      commit('SELECT_MODAL_TYPE', type)
      commit('TOGGLE_OVERLAY', null, {root: true})
      commit('SET_MODAL_DATA', {
        data: {
          userId,
          title,
          description,
          confirmButton,
          cancelButton,
        },
        confirmAction,
        cancelAction,
      })
    },
    ToggleModalRemoveParticipants({commit}, { userId, confirmAction, cancelAction, title, description, confirmButton, cancelButton, type }) {
      commit('TOGGLE_MODAL')
      commit('SELECT_MODAL_TYPE', type)
      commit('TOGGLE_OVERLAY', null, {root: true})
      commit('SET_MODAL_DATA', {
        data: {
          userId,
          title,
          description,
          confirmButton,
          cancelButton,
        },
        confirmAction,
        cancelAction,
      })
    },
    ToggleModalIconsModal({commit}, { confirmAction, cancelAction }) {
      commit('TOGGLE_MODAL')
      commit('SELECT_MODAL_TYPE', 'icons')
      commit('TOGGLE_OVERLAY', null, {root: true})
      commit('SET_MODAL_DATA', {
        confirmAction,
        cancelAction,
        data: {
          title: 'Choose Your Category Icon'
        }
      })
    },
    ToggleModalAwardsModal({commit}, { confirmAction, cancelAction }) {
      commit('TOGGLE_MODAL')
      commit('SELECT_MODAL_TYPE', 'awards')
      commit('TOGGLE_OVERLAY', null, {root: true})
      commit('SET_MODAL_DATA', {
        confirmAction,
        cancelAction,
        data: {
          title: 'Choose Your Award Icon'
        }
      })
    },
    ToggleAssignAwardsModal({commit}, { confirmAction, cancelAction, data }) {
        commit('TOGGLE_MODAL')
        commit('SELECT_MODAL_TYPE', 'assignAwards')
        commit('TOGGLE_OVERLAY', null, {root: true})
        commit('SET_MODAL_DATA', {
            confirmAction,
            cancelAction,
            data,
        })
    },
    ToggleModalGeneralConfirm({commit}, { confirmAction, cancelAction, title, description, confirmButton, cancelButton }) {
      commit('TOGGLE_MODAL')
      commit('SELECT_MODAL_TYPE', 'confirm')
      commit('TOGGLE_OVERLAY', null, {root: true})
      commit('SET_MODAL_DATA', {
        data: {
          title,
          description,
          confirmButton,
          cancelButton,
        },
        confirmAction,
        cancelAction,
      })
    },
    ToggleModalSponsorsModal({commit}, { confirmAction, cancelAction }) {
      commit('TOGGLE_MODAL')
      commit('SELECT_MODAL_TYPE', 'sponsors')
      commit('TOGGLE_OVERLAY', null, {root: true})
      commit('SET_MODAL_DATA', {
        data: {
          title: 'Upload Your Sponsor Logo',
          features: ['upload']
        },
        confirmAction,
        cancelAction,
      })
    },
    ToggleIntegrationConfirmationModal({commit}) {
      commit('SHOW_MODAL')
      commit('SELECT_MODAL_TYPE', 'integrationConfirmation')
      commit('SHOW_OVERLAY', null, {root: true})
    },
    ToggleProjectDeclineReasonModal({commit}, { confirmAction, cancelAction, projectId }) {
        commit('SHOW_MODAL')
        commit('SELECT_MODAL_TYPE', 'projectDeclineReason')
        commit('TOGGLE_OVERLAY', null, {root: true})
        commit('SET_MODAL_DATA', {
            confirmAction,
            cancelAction,
            data: {
              projectId: projectId
            }
        })
    }
  }
}

