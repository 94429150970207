<script setup>
import ModalBase from "@/components/Modals/ModalBase"
import BaseButton from '@/components/Base/Button/BaseButton'
import store from '@/store'
import { useRouter } from 'vue-router'
import { computed } from 'vue'
const router = new useRouter()

const event = computed(() => {
  return store.getters.eventBuilderEvent
})

const submit = () => {
  store.dispatch('ToggleModalSubmissionEndError')
  router.push({ name: 'Timeline', params: { slug: event.value.slug }})
  window.scrollTo(0, 0)
}
</script>

<template>
  <modal-base modal-class="bg-white modal-eventSubmissionEndErr" :is-close="true">
    <template #modal-title>
      <div class="modal-eventSubmissionEndErr-title">
        <img class="modal-eventSubmissionEndErr-title-img" src="https://firebasestorage.googleapis.com/v0/b/test-d9796.appspot.com/o/selfserve%2Fcalendar-star-duotone%201.png?alt=media&token=b5c51406-66da-4650-bdc4-4e73ff8aa392"/>
        <h1 class="modal-eventSubmissionEndErr-title-header">Adjust Submission End Date</h1>
      </div>
    </template>
    <template #modal-content>
      <div class="modal-eventSubmissionEndErr-content">
        <p class="modal-eventSubmissionEndErr-content-para">It looks like your submission end date is in the past. Please click the button below to set a future date.</p>
      </div>
    </template>
    <template #modal-button>
      <div class="modal-eventSubmissionEndErr-buttons">
          <base-button
            id="go-live"
            name="go-live"
            text="Go to timeline"
            aria-label="go Button"
            class-name="modal-eventSubmissionEndErr-buttons-live"
            :functions="submit"/>
        </div>
    </template>
  </modal-base>
</template>

<style lang="scss" scoped>
.modal-eventSubmissionEndErr {
  @apply md:h-auto max-w-lg pb-7 rounded-b-none md:rounded-2xl overflow-y-auto p-5;
  &-title {
    @apply p-2;
    &-header {
      @apply md:text-h2 mb-3 w-full text-center font-medium pt-7;
    }
    &-img {
      @apply w-20 mx-auto mb-5;
    }
  }
  &-content {
    &-para {
      @apply max-w-[23rem] px-3 text-p-xl text-center mx-auto md:leading-6 leading-5 text-blacks-300;
      &-span {
        @apply text-other-green font-medium md:text-h5;
      }
    }
  }
  &-buttons {
    @apply px-5 md:flex-row-reverse mt-8 text-center;
    &-live {
      @apply md:max-w-[12rem] md:mx-2 capitalize w-full md:mb-0 mb-3 h-11;
    }
  }
}
</style>
